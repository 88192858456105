/* @font-face {
  font-family: 'Obviously';
  src: url('./Obviously-Thin.eot');
  src: local('Obviously'), url('./Obviously-Thin.woff') format('woff'),
    url('./Obviously-Thin.ttf') format('truetype');
} */

@font-face {
  font-family: 'Obviously';
  /* src: url('./Obviously-Regular.otf'); */
  src: url('./ObviouslyNarrow-Regular.otf');
}
